<template>
  <div id="app">
    <div class="header">
      <div class="container text-center py-4">
        <img src="/lamusica-white.svg" alt="LaMusica logo white">
      </div>
    </div>

    <stations></stations>
  </div>
</template>

<script>
import Stations from "@/components/stations.vue";

export default {
  name: 'App',
  components: {
    Stations
  }
}
</script>

<style lang="scss">
  #app {
    color: #7d7d7d;
  }

  .container {
    max-width: 900px !important;
  }

  .header {
    background-color: #000;
    border-bottom: #000 solid 2px;
    img {
      width: 177px;
      line-height: 32px;
    }
  }

</style>
