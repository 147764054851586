<template>
  <div class="station-wrapper mt-4" v-bind:class="{'player-visible': currentResource}">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-6 mb-4" v-for="station in stations" :key="station._id">
          <div class="station-image" @click="openPlayer(station)">
            <img alt="" :src="station.images.detail['2x']" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <la-musica-audio-player :resource="currentResource" v-if="currentResource" @close="onPlayerClosed"></la-musica-audio-player>
  </div>
</template>
<script>

import axios from 'axios'
import LaMusicaAudioPlayer from "@/components/audio-player/lamusica-audio-player.vue";
export default {
  name: 'Stations',
  components: { LaMusicaAudioPlayer },
  data () {
    return {
      stations: [],
      currentResource: null
    }
  },
  async mounted () {
    await this.loadStations()
  },
  methods: {
    async loadStations() {
      try {
        const response = await axios.get('https://rictf9iszk.execute-api.us-east-1.amazonaws.com/Development')
        this.stations = response.data.items
      }catch (error) {
        console.log(error)
      }
    },

    openPlayer(station) {
      const metadata = {
        title: station.title.es,
        artist: 'LaMusica',
        image: station.images.detail['2x'],
        resource: null
      }

      this.currentResource = null

      this.$nextTick(() => {
        this.currentResource = {
          source: [
            {
              _id: station._id,
              image: metadata.image,
              type: 'audio/aac',
              src: (station.stream_settings && station.stream_settings.stream_url_aac) ? station.stream_settings.stream_url_aac : null,
              title: metadata.title,
              artist: metadata.artist
            }
          ],
          metadata
        }
      })
    },

    onPlayerClosed() {
      this.currentResource = null
    }
  }
}
</script>
<style scoped lang="scss">
  .station-image {
    img {
      border-radius: 5px;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .station-wrapper {
    &.player-visible {
      padding-bottom: 80px;
    }
  }

</style>
