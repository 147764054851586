<template>
  <div>
    <div class="audio-player-wrapper visible">
      <div class="content-wrapper">
        <div class="metadata-wrapper">
          <div class="inner-wrapper">
            <img v-if="metadata.image" :src="metadata.image" alt="">
            <div class="text">
              <div v-if="metadata.title" v-b-tooltip :title="metadata.title" class="title" v-html="metadata.title" />
              <div v-if="metadata.artist" v-b-tooltip :title="metadata.artist" class="artist" v-html="metadata.artist" />
              <div v-if="metadata.resource" v-b-tooltip :title="metadata.resource" class="resource" v-html="metadata.resource" />
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="loading-icon" />
        <div v-else class="controls-wrapper">
          <div class="control volume-control">
            <vue-slider v-if="volume" v-model="volume" :min="1" @change="changeVolume" />
          </div>

          <div v-if="isPaused" class="control" @click="play">
            <b-icon icon="play-fill"></b-icon>
          </div>
          <div v-if="isPlaying" class="control" @click="pause">
            <b-icon icon="pause-fill"></b-icon>
          </div>

          <div class="control" @click="stop">
            <b-icon icon="x-circle-fill"></b-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/material.css'

export default {
  name: 'LaMusicaAudioPlayer',
  components: { VueSlider },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      player: null,
      playerHtmlElement: null,
      volume: null,
      metadata: {},
      status: ''
    }
  },
  computed: {
    isPaused () {
      return this.status === 'paused'
    },

    isPlaying () {
      return this.status === 'playing'
    },

    isLoading () {
      return this.status === 'loading'
    }
  },
  mounted () {
    this.metadata = this.resource.metadata
    this.playResource(this.resource)
  },
  methods: {
    changeVolume (value) {
      this.player.volume(value / 100)
      this.volume = value
    },
    playResource () {
      if (!this.player) {
        this.initPlayer()
      } else {
        this.updatePlayer()
      }
    },

    initPlayer () {
      this.playerHtmlElement = document.querySelector('audio')
      this.status = 'loading'

      this.player = videojs(this.playerHtmlElement, {
        language: 'en',
        controls: true,
        responsive: true,
        fluid: true
      })

      this.attachPlayerGlobalEvents()
      this.updatePlayerSource()

      this.player.ready(() => {
        this.play()
      })
    },

    updatePlayer () {
      this.pause()
      this.updatePlayerSource(this.currentIndex)
    },

    play () {
      if (this.player) {
        this.player.play()
      }
    },

    pause () {
      if (this.player) {
        this.player.pause()
      }
    },

    stop() {
      this.player.pause()
      this.player.off('play')
      this.player.off('pause')
      this.player.off('canplay')
      this.player.off('error')
      this.player = null
      this.$emit('close')
    },

    async updatePlayerSource () {
      this.player.src({
        type: this.resource.source[0].type,
        src: this.resource.source[0].src
      })
    },

    // Events
    attachPlayerGlobalEvents () {
      this.player.on('play', this.onPlayerPlay)
      this.player.on('pause', this.onPlayerPause)
      this.player.on('canplay', this.onPlayerCanPlay)
      this.player.on('error', this.onPlayerError)
    },

    onPlayerPlay () {
      this.status = 'playing'
    },

    onPlayerPause () {
      this.status = 'paused'
    },

    onPlayerCanPlay () {
      if (!this.volume) {
        this.player.volume(0.5)
        this.volume = 50
      } else {
        this.player.volume(this.volume / 100)
        this.volume = this.player.volume() * 100
      }
    },

    onPlayerError (error) {
      this.status = 'paused'
      console.log({ error })
    }
  }
}
</script>

<style lang="scss">
.vue-slider-process,
.vue-slider-dot-handle,
.vue-slider-dot-tooltip-inner {
  background-color: #f6494a !important;
}

.vue-slider-rail {
  background-color: #d0d0d0 !important;
}

.vue-slider-dot-handle::after {
  background-color: rgba(218, 11, 11, 0.38) !important;
}
</style>

<style lang="scss" scoped>
.audio-player-wrapper {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: all .3s;
  background-color: #1f1e1e;

  &.visible {
    bottom: 0;
  }

  .content-wrapper {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    transition: all .3s;

    .metadata-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-grow: 1;

      .inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        img {
          max-height: 80px;
          margin-right: 10px;
          width: auto;
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 350px;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .title {
            margin-bottom: 3px;
            font-size: 1rem;
            color: #fff;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .artist {
            font-size: .85rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .resource {
            font-size: .8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .controls-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      .control {
        cursor: pointer;
        font-size: 25px;
        color: #fff;
        line-height: 25px;
        margin: 0 10px;

        &.volume-control {
          width: 100px;
          padding: 0 10px;
        }
      }
    }

    .display-ads-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: #181818;
      border: 1px solid #2f2c2c;
      width: 468px;
      height: 60px;
    }

    .loading-icon {
      margin-right: 20px;
      border: 4px solid #f2494a;
      border-radius: 50%;
      border-bottom-color: #f3f3f3;
      width: 20px;
      height: 20px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @media (max-width: 500px) {
      .metadata-wrapper {
        .inner-wrapper {
          .text {
            max-width: 120px !important;

            .title {
              font-size: .8rem;
            }

            .artist {
              font-size: .78rem;
            }

            .resource {
              font-size: .7em;
            }
          }
        }
      }
      .controls-wrapper {
        .volume-control {
          display: none;
        }

        .control {
          font-size: 20px;
          margin: 0 5px;
        }
      }
      .display-ads-wrapper {
        display: none;
      }
    }

    @media (max-width: 991px) {
      .display-ads-wrapper {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      .metadata-wrapper {
        .inner-wrapper {
          .text {
            max-width: 140px;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      .metadata-wrapper {
        .inner-wrapper {
          .text {
            max-width: 200px;
          }
        }
      }
    }
  }
}

.radio-station-companion-banner {
  position: fixed;
  left: -315px;
  bottom: 85px;
  background-color: rgba(0, 0, 0, .95);
  z-index: 999999991;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;
  border: 1px solid #3e3e3e;
  padding: 5px;
  border-radius: 5px;

  iframe {
    img {
      border-radius: 5px;
    }
  }

  &.show {
    -webkit-animation: show 0.5s forwards;
    animation: show 0.5s forwards;
  }

  &.hide {
    -webkit-animation: hide 0.5s forwards;
    animation: hide 0.5s backwards;
  }

  .companion-ads-wrapper {
    width: 300px;
    height: 250px;
  }
}

@-webkit-keyframes show {
  100% {
    left: 0;
  }
}

@keyframes hide {
  100% {
    left: -315px;
  }
}
</style>
